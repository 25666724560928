import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Container, Grid, makeStyles } from "@material-ui/core"

import "./hero.css"

const useStyles = makeStyles(theme => ({
  paragraph: {
    ...theme.typography.body1,
  },
  hero: {
    padding: theme.spacing(2),
  },
  title: {
    color: "white",
    fontFamily: "Cutive Mono",
    fontWeight: 700,
    letterSpacing: "0.05em",
    fontSize: "2em",
    lineHeight: "1rem",
  },
  subTitle: {
    color: "white",
    fontFamily: "Cutive Mono",
    fontWeight: 500,
    fontSize: "1.2em",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignContent: "space-between",
  },
}))

const Hero2 = props => {
  const classes = useStyles()
  const { fluid } = props
  return (
    <>
      <div className={classes.hero}>
        <Container component="main" className={classes.content} maxWidth="md">
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={8}>
              <Link to="/">
                <Img fluid={fluid} />
              </Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default Hero2
