/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles, Typography } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

import "./layout.css"

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(4),
    backgroundColor: grey[800],
    color: grey[300],
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div>
        <main>{children}</main>
      </div>
      <footer className={classes.footer}>
        <center>
          <Typography variant="caption" align="center">
            © {new Date().getFullYear()} {data.site.siteMetadata.title}
          </Typography>
        </center>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
