import React from "react"
import { Link, graphql } from "gatsby"
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import "../components/hero.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero2 from "../components/hero2"

const useStyles = makeStyles(theme => ({
  paragraph: {
    ...theme.typography.body1,
  },
  title: {
    color: red[600],
    fontWeight: 100,
  },
  button: {
    textDecoration: "none",
  },
  borderTop: {
    borderTop: `10px solid ${red[600]}`,
  },
  main: {
    minHeight: "65vh",
  },
}))

const NotFoundPage = ({ data }) => {
  const classes = useStyles()

  return (
    <>
      <Hero2 fluid={data.placeholderImage.childImageSharp.fluid} />
      <div className={classes.borderTop}></div>
      <Layout>
        <Container component="main" className={classes.main} maxWidth="md">
          <SEO title="Releases" />
          <Typography component="div">
            <Box
              fontSize="h4.fontSize"
              textAlign="center"
              m={1}
              className={[classes.title, "arvo"].join(" ")}
            >
              Oops ... page not found :(
            </Box>
          </Typography>

          <Link to="/" className={classes.button}>
            <Button fullWidth>Back</Button>
          </Link>

          <Box textAlign="center" m={1} className={classes.list}>
            <Grid container alignItems="" spacing={2}></Grid>
          </Box>
        </Container>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "CorComLogo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
